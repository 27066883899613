import {CompanyPaymentPlan, CompanyType, EntryPoint} from 'hubbl-shared';
import {createAppAsyncThunk} from '../appAsyncThunk';
import {Api} from '../../api';

export const setGetStartedCompanyType = createAppAsyncThunk(
  'get-started/setGetStartedCompanyType',
  async (selectedCompanyType: CompanyType) => {
    return selectedCompanyType;
  },
);
export const setWrittenCompanyName = createAppAsyncThunk(
  'get-started/setWrittenCompanyName',
  async (writtenCompanyName: string) => {
    return writtenCompanyName;
  },
);

export const setWrittenEmail = createAppAsyncThunk(
  'get-started/setWrittenEmail',
  async (setWrittenEmail: string) => {
    return setWrittenEmail;
  },
);
export const setWrittenPhonenumber = createAppAsyncThunk(
  'get-started/setWrittenPhonenumber',
  async (writtenPhonenumber: string) => {
    return writtenPhonenumber;
  },
);

export const setSelectedActivities = createAppAsyncThunk(
  'get-started/setSelectedActivities',
  async (selectedActivities: string[]) => {
    return selectedActivities;
  },
);

export const setSelectedPaymentPlan = createAppAsyncThunk(
  'get-started/setSelectedPaymentPlan',
  async (paymentPlan: CompanyPaymentPlan) => {
    return paymentPlan;
  },
);

export const setEntryPoint = createAppAsyncThunk(
  'get-started/setEntryPoint',
  async (entryPoint: EntryPoint) => {
    return entryPoint;
  },
);

export const createCompany = createAppAsyncThunk(
  'get-started/createCompany',
  async (captchaToken: string, thunkApi) => {
    const state = thunkApi.getState();

    const {
      selectedCompanyType,
      writtenCompanyName,
      writtenEmail,
      entryPoint,
      writtenPhonenumber,
      selectedPaymentPlan,
    } = state.getStarted;

    const results = await Api.company.createCompany({
      companyType: selectedCompanyType,
      creatorEmail: writtenEmail,
      token: captchaToken,
      spaceName: writtenCompanyName,
      creatorPhoneNumber: writtenPhonenumber,
      paymentPlan: selectedPaymentPlan,
      entryPoint,
    });

    return {pincode: results.pincode, writtenCompanyName};
  },
);
