import {Palette} from 'hubbl-shared';
import {ReactNode, memo} from 'react';
import styled from 'styled-components';
import {PaddingRight} from '../../padding/PaddingRight';
import {TextHeading7} from '../../text/TextHeading7';

interface IconButtonProps {
  onClick: () => void;
  icon: ReactNode;
  text?: string;
  color?: Palette;
  hoverColor?: Palette;
  iconPadding?: number;
  backgroundColor?: Palette;
}

const Button = styled.button<
  Pick<IconButtonProps, 'hoverColor' | 'backgroundColor'>
>`
  background-color: ${props => props.backgroundColor};
  padding: 5px 10px;
  border-radius: 5px;
  border-width: 0px;
  outline: 0;
  cursor: pointer;
  transition: ease background-color 250ms;
  &:hover {
    background-color: ${props =>
      props.hoverColor ? props.hoverColor : Palette.primaryCherokee};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconButton = memo(
  ({
    icon,
    onClick,
    text,
    color,
    hoverColor,
    iconPadding = 12,
    backgroundColor = Palette.transparent,
  }: IconButtonProps) => {
    return (
      <Button
        backgroundColor={backgroundColor}
        hoverColor={hoverColor}
        onClick={onClick}>
        {icon}
        {text && (
          <>
            <PaddingRight amount={iconPadding} />
            <TextHeading7 text={text} color={color} />
          </>
        )}
      </Button>
    );
  },
);
