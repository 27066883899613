import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '..';

const getSelectedCompanyType = createSelector(
  (state: RootState) => state.getStarted.selectedCompanyType,
  companyType => companyType,
);
const getWrittenCompanyName = createSelector(
  (state: RootState) => state.getStarted.writtenCompanyName,
  writtenCompanyName => writtenCompanyName,
);
const getWrittenEmail = createSelector(
  (state: RootState) => state.getStarted.writtenEmail,
  writtenEmail => writtenEmail,
);
const getWrittenPhonenumber = createSelector(
  (state: RootState) => state.getStarted.writtenPhonenumber,
  writtenPhonenumber => writtenPhonenumber,
);
const getSelectedActivities = createSelector(
  (state: RootState) => state.getStarted.selectedActivities,
  selectedActivities => selectedActivities,
);
const getSelectedPaymentPlan = createSelector(
  (state: RootState) => state.getStarted.selectedPaymentPlan,
  selectedPaymentPlan => selectedPaymentPlan,
);
const getEntryPoint = createSelector(
  (state: RootState) => state.getStarted.entryPoint,
  entryPoint => entryPoint,
);

export const GetStartedSelector = {
  getSelectedCompanyType,
  getWrittenCompanyName,
  getEntryPoint,
  getSelectedActivities,
  getSelectedPaymentPlan,
  getWrittenEmail,
  getWrittenPhonenumber,
};
