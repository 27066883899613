import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '..';

const getIsBusy = createSelector(
  (state: RootState) => state.global.isBusy,
  isBusy => isBusy,
);

export const GlobalSelector = {
  getIsBusy,
};
