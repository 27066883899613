import {Scene} from '../../components';

import getStartedBackgroundImage from '../../assets/images/getStartedBackgroundImage.png';
import {GetStartedWizard} from './components/wizard/GetStartedWizard';
import {useSearchParams} from 'react-router-dom';
import {useEffect} from 'react';
import {useAppDispatch} from '../../hooks';
import {GetStartedActions} from '../../store/get-started/GetStartedActions';
import {isCompanyPaymentPlan, isEntryPoint} from 'hubbl-shared';
import {isMobile} from 'react-device-detect';

export const SceneGetStarted = () => {
  const [searchParams] = useSearchParams();
  const plan = searchParams.get('plan');
  const entryPoint = searchParams.get('entry');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isEntryPoint(entryPoint)) {
      let adjustedEntryPoint = entryPoint;

      if (entryPoint === 'desktop' && isMobile) {
        adjustedEntryPoint = 'mobile';
      }

      dispatch(GetStartedActions.setEntryPoint(adjustedEntryPoint));
    }

    isCompanyPaymentPlan(plan) &&
      dispatch(GetStartedActions.setSelectedPaymentPlan(plan));
  }, [dispatch, plan, entryPoint]);

  return (
    <div
      style={{
        backgroundImage: `url(${getStartedBackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        overflow: 'auto',
        scrollbarWidth: 'thin',
      }}>
      <Scene>
        <GetStartedWizard />
      </Scene>
    </div>
  );
};
