import {Palette} from 'hubbl-shared';
import {ForwardedRef, forwardRef, Fragment} from 'react';
import styled from 'styled-components';
import {FontConfig} from '../../constants/FontConfig';
import {FieldError} from './FieldError';

export interface InputProps {
  hasError?: boolean;

  validationErrorMessage?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  multiline?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  value?: string | number | readonly string[] | undefined;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      hasError,
      onBlur,
      value,
      onChange,
      validationErrorMessage,
      multiline,
      isDisabled,
      ...props
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <Fragment>
        <InputRow>
          <InputContainer
            style={{
              backgroundColor: Palette.whiteTransparent60,
              borderColor: hasError ? Palette.systemError : Palette.white,
              borderWidth: 1,
            }}>
            <StyledInput
              ref={ref}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              //onChange={e => props.onChange?.(e.target.value)}
              placeholder={props.placeholder}
              disabled={isDisabled}
              hasError={hasError}
              multiline={multiline}
              {...props}
            />
          </InputContainer>
        </InputRow>
        {validationErrorMessage && (
          <FieldError
            errorMessage={validationErrorMessage}
            hasError={hasError}
          />
        )}
      </Fragment>
    );
  },
);

// Styled Components

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 24px; /* Use this for border-radius */
  background-color: white;
  border: 1px solid #ccc;
`;

const InputFontConfig = FontConfig.Heading3;

const StyledInput = styled.input<InputProps & {hasError?: boolean}>`
  width: 100%;
  height: 100%;
  font-weight: ${() => InputFontConfig.fontWeight};
  font-size: ${() =>
    `${InputFontConfig.fontSize}px`}; /* Ensure it appends 'px' */
  font-family: ${() => InputFontConfig.fontFamily}; /* Fallback to FontConfig */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  color: #333;
  outline: none;

  &.disabled {
    background-color: #f0f0f0;
    color: #999;
  }

  ${({multiline}) =>
    multiline &&
    `
      height: 166px;
      padding: 16px;
      text-align: left;
      vertical-align: top;
  `}
`;
