import {memo, useCallback, useMemo} from 'react';
import {useWizard} from 'react-use-wizard';
import styled from 'styled-components';
import {PrimaryButton, SecondaryButton} from '../../../../../../components';
import {isMobile} from 'react-device-detect';

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  gap: 1rem;
  flex-direction: row;
  margin-top: 40px;
`;

interface GetStartedWizardFooterProps {
  onCustomNextStep?: () => void;
  isBackHidden?: boolean;
}

export const GetStartedWizardFooter = memo(
  ({onCustomNextStep, isBackHidden = false}: GetStartedWizardFooterProps) => {
    const {nextStep, previousStep, isLastStep} = useWizard();

    const onNextStepInternal = useCallback(() => {
      onCustomNextStep ? onCustomNextStep() : nextStep();
    }, [nextStep, onCustomNextStep]);

    const onPrevStep = useCallback(() => previousStep(), [previousStep]);

    const primaryButtonText = useMemo(() => {
      if (isLastStep) {
        return 'Create space';
      } else {
        return 'Next';
      }
    }, [isLastStep]);

    return (
      <Actions
        style={isMobile ? {flexDirection: 'column', display: 'flex'} : {}}>
        {!isBackHidden && (
          <SecondaryButton label="Previous" onClick={onPrevStep} />
        )}
        <PrimaryButton label={primaryButtonText} onClick={onNextStepInternal} />
      </Actions>
    );
  },
);
