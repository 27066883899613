import {memo} from 'react';

interface AppLogoProps {
  size?: number;
}

export const AssetAppleAppStoreButton = memo(({size = 135}: AppLogoProps) => {
  return (
    <svg
      width={size}
      height={size * (40 / 135)}
      viewBox="0 0 135 40"
      fill="none">
      <rect width="135" height="40" rx="8" fill="#2E2E2D" />
      <path
        d="M32.7973 18.8636C32.8081 18.029 33.0298 17.2106 33.4418 16.4846C33.8538 15.7587 34.4428 15.1487 35.1539 14.7116C34.7021 14.0664 34.1062 13.5355 33.4134 13.1609C32.7205 12.7863 31.9499 12.5785 31.1627 12.5539C29.4835 12.3776 27.8555 13.5587 26.9998 13.5587C26.1275 13.5587 24.81 12.5714 23.3913 12.6006C22.4736 12.6302 21.5793 12.8971 20.7954 13.3751C20.0115 13.8532 19.3649 14.5261 18.9184 15.3284C16.9844 18.6769 18.427 23.5979 20.2796 26.3045C21.2065 27.6299 22.2898 29.1103 23.7072 29.0578C25.0943 29.0003 25.6123 28.1733 27.2866 28.1733C28.9454 28.1733 29.4314 29.0578 30.8776 29.0244C32.366 29.0003 33.3038 27.6932 34.1981 26.3553C34.8641 25.4109 35.3766 24.3672 35.7166 23.2628C34.8518 22.8971 34.1139 22.2849 33.5948 21.5026C33.0757 20.7203 32.7983 19.8025 32.7973 18.8636Z"
        fill="white"
      />
      <path
        d="M30.0657 10.7738C30.8772 9.79961 31.277 8.54744 31.1802 7.2832C29.9404 7.41342 28.7951 8.00598 27.9726 8.94281C27.5705 9.40048 27.2625 9.93291 27.0662 10.5097C26.87 11.0865 26.7893 11.6962 26.8289 12.3042C27.4491 12.3106 28.0626 12.1762 28.6232 11.9111C29.1839 11.646 29.6771 11.2571 30.0657 10.7738Z"
        fill="white"
      />
      <path
        d="M50.3307 25.7026H45.5973L44.4606 29.059H42.4557L46.9391 16.641H49.0221L53.5055 29.059H51.4664L50.3307 25.7026ZM46.0875 24.1538H49.8395L47.9899 18.7065H47.9382L46.0875 24.1538Z"
        fill="white"
      />
      <path
        d="M63.1881 24.5327C63.1881 27.3461 61.6823 29.1538 59.4098 29.1538C58.8342 29.1839 58.2617 29.0513 57.7579 28.7711C57.2541 28.491 56.8393 28.0746 56.5612 27.5698H56.5182V32.0541H54.6598V20.0053H56.4586V21.5112H56.4928C56.7838 21.0087 57.2055 20.5945 57.7131 20.3127C58.2208 20.0308 58.7953 19.8919 59.3756 19.9106C61.6734 19.9106 63.1881 21.727 63.1881 24.5327ZM61.278 24.5327C61.278 22.6997 60.3307 21.4946 58.8854 21.4946C57.4655 21.4946 56.5104 22.725 56.5104 24.5327C56.5104 26.3569 57.4655 27.5786 58.8854 27.5786C60.3307 27.5786 61.278 26.3823 61.278 24.5327Z"
        fill="white"
      />
      <path
        d="M73.153 24.5327C73.153 27.3461 71.6471 29.1538 69.3746 29.1538C68.799 29.1839 68.2265 29.0513 67.7227 28.7711C67.2189 28.491 66.8042 28.0746 66.526 27.5698H66.483V32.0541H64.6246V20.0053H66.4234V21.5112H66.4576C66.7486 21.0087 67.1703 20.5945 67.6779 20.3127C68.1856 20.0308 68.7601 19.8919 69.3404 19.9106C71.6383 19.9106 73.153 21.727 73.153 24.5327ZM71.2428 24.5327C71.2428 22.6997 70.2955 21.4946 68.8502 21.4946C67.4303 21.4946 66.4752 22.725 66.4752 24.5327C66.4752 26.3569 67.4303 27.5786 68.8502 27.5786C70.2955 27.5786 71.2428 26.3823 71.2428 24.5327Z"
        fill="white"
      />
      <path
        d="M79.7389 25.5991C79.8766 26.8305 81.0729 27.6391 82.7077 27.6391C84.2741 27.6391 85.401 26.8305 85.401 25.7201C85.401 24.7562 84.7213 24.1791 83.112 23.7836L81.5026 23.3959C79.2223 22.8451 78.1637 21.7787 78.1637 20.0482C78.1637 17.9057 80.0309 16.434 82.6823 16.434C85.3063 16.434 87.1051 17.9057 87.1657 20.0482H85.2897C85.1774 18.809 84.1529 18.0609 82.6559 18.0609C81.1588 18.0609 80.1344 18.8178 80.1344 19.9193C80.1344 20.7973 80.7887 21.3139 82.3893 21.7093L83.7574 22.0453C86.3053 22.6478 87.3639 23.6713 87.3639 25.4877C87.3639 27.8109 85.5133 29.266 82.5699 29.266C79.816 29.266 77.9567 27.8451 77.8365 25.599L79.7389 25.5991Z"
        fill="white"
      />
      <path
        d="M91.3746 17.8627V20.0053H93.0963V21.477H91.3746V26.4682C91.3746 27.2436 91.7194 27.6049 92.4762 27.6049C92.6806 27.6014 92.8847 27.587 93.0875 27.5619V29.0248C92.7473 29.0884 92.4014 29.1172 92.0553 29.1108C90.2223 29.1108 89.5075 28.4223 89.5075 26.6664V21.477H88.1911V20.0053H89.5074V17.8627H91.3746Z"
        fill="white"
      />
      <path
        d="M94.0934 24.5327C94.0934 21.684 95.7712 19.894 98.3874 19.894C101.012 19.894 102.682 21.684 102.682 24.5327C102.682 27.3891 101.021 29.1713 98.3874 29.1713C95.7545 29.1713 94.0934 27.3891 94.0934 24.5327ZM100.789 24.5327C100.789 22.5786 99.8932 21.4252 98.3874 21.4252C96.8815 21.4252 95.987 22.5874 95.987 24.5327C95.987 26.4946 96.8815 27.6391 98.3874 27.6391C99.8932 27.6391 100.789 26.4946 100.789 24.5327Z"
        fill="white"
      />
      <path
        d="M104.214 20.0053H105.987V21.5463H106.03C106.15 21.065 106.432 20.6397 106.828 20.3418C107.225 20.0439 107.712 19.8916 108.208 19.9106C108.422 19.9098 108.635 19.9331 108.844 19.9799V21.7182C108.574 21.6356 108.292 21.5976 108.009 21.6059C107.739 21.5949 107.47 21.6425 107.22 21.7454C106.971 21.8483 106.746 22.004 106.562 22.2019C106.378 22.3998 106.239 22.6352 106.155 22.8919C106.07 23.1485 106.042 23.4204 106.073 23.6889V29.059H104.214L104.214 20.0053Z"
        fill="white"
      />
      <path
        d="M117.413 26.3998C117.163 28.0434 115.562 29.1713 113.514 29.1713C110.881 29.1713 109.246 27.4067 109.246 24.5756C109.246 21.7358 110.889 19.894 113.436 19.894C115.941 19.894 117.516 21.6147 117.516 24.3598V24.9965H111.122V25.1088C111.092 25.442 111.134 25.7777 111.244 26.0935C111.354 26.4094 111.53 26.6982 111.761 26.9407C111.991 27.1833 112.27 27.374 112.58 27.5003C112.89 27.6266 113.223 27.6856 113.557 27.6733C113.996 27.7144 114.437 27.6127 114.814 27.3833C115.191 27.1538 115.483 26.8089 115.648 26.3998L117.413 26.3998ZM111.131 23.6977H115.657C115.674 23.3981 115.628 23.0983 115.523 22.8171C115.419 22.5359 115.257 22.2793 115.049 22.0636C114.84 21.8478 114.589 21.6775 114.312 21.5632C114.034 21.449 113.736 21.3934 113.436 21.3998C113.134 21.398 112.834 21.4562 112.554 21.5709C112.273 21.6857 112.019 21.8547 111.805 22.0684C111.59 22.282 111.42 22.536 111.305 22.8156C111.189 23.0953 111.13 23.3951 111.131 23.6977Z"
        fill="white"
      />
      <path
        d="M45.8545 7.294C46.2441 7.26604 46.6351 7.3249 46.9992 7.46633C47.3633 7.60777 47.6915 7.82825 47.9601 8.11187C48.2286 8.3955 48.4309 8.7352 48.5523 9.10647C48.6737 9.47774 48.7112 9.87132 48.6621 10.2588C48.6621 12.1651 47.6318 13.2608 45.8545 13.2608H43.6992V7.294H45.8545ZM44.626 12.417H45.751C46.0294 12.4336 46.308 12.388 46.5665 12.2834C46.8251 12.1788 47.057 12.0178 47.2455 11.8123C47.434 11.6067 47.5743 11.3617 47.6562 11.0951C47.738 10.8284 47.7594 10.5469 47.7187 10.271C47.7564 9.99617 47.7329 9.71639 47.6498 9.45173C47.5667 9.18707 47.426 8.94406 47.238 8.74013C47.0499 8.5362 46.8191 8.37639 46.562 8.27216C46.3049 8.16792 46.028 8.12184 45.751 8.13721H44.626V12.417Z"
        fill="white"
      />
      <path
        d="M49.709 11.0073C49.6807 10.7114 49.7145 10.4129 49.8084 10.1308C49.9022 9.84871 50.054 9.58937 50.2539 9.36939C50.4538 9.14941 50.6976 8.97364 50.9694 8.85337C51.2413 8.73309 51.5352 8.67096 51.8325 8.67096C52.1298 8.67096 52.4238 8.73309 52.6956 8.85337C52.9675 8.97364 53.2112 9.14941 53.4111 9.36939C53.6111 9.58937 53.7628 9.84871 53.8567 10.1308C53.9505 10.4129 53.9844 10.7114 53.956 11.0073C53.9849 11.3036 53.9514 11.6025 53.8579 11.8851C53.7643 12.1676 53.6126 12.4274 53.4126 12.6478C53.2126 12.8682 52.9687 13.0444 52.6966 13.1649C52.4245 13.2854 52.1301 13.3477 51.8325 13.3477C51.5349 13.3477 51.2406 13.2854 50.9684 13.1649C50.6963 13.0444 50.4524 12.8682 50.2524 12.6478C50.0524 12.4274 49.9008 12.1676 49.8072 11.8851C49.7136 11.6025 49.6801 11.3036 49.709 11.0073ZM53.042 11.0073C53.042 10.0313 52.6035 9.46047 51.834 9.46047C51.0615 9.46047 50.627 10.0313 50.627 11.0073C50.627 11.9912 51.0615 12.5576 51.834 12.5576C52.6035 12.5576 53.042 11.9873 53.042 11.0073Z"
        fill="white"
      />
      <path
        d="M59.6016 13.2607H58.6797L57.749 9.94434H57.6787L56.752 13.2607H55.8389L54.5977 8.75782H55.499L56.3057 12.1938H56.3721L57.2979 8.75782H58.1504L59.0762 12.1938H59.1465L59.9492 8.75782H60.8379L59.6016 13.2607Z"
        fill="white"
      />
      <path
        d="M61.8818 8.75782H62.7373V9.47315H62.8037C62.9164 9.21623 63.1064 9.00086 63.3472 8.85705C63.5881 8.71324 63.8678 8.64817 64.1475 8.6709C64.3666 8.65443 64.5866 8.68746 64.7912 8.76756C64.9958 8.84765 65.1797 8.97276 65.3294 9.1336C65.4791 9.29445 65.5906 9.48691 65.6558 9.69673C65.7211 9.90655 65.7382 10.1283 65.706 10.3457V13.2607H64.8174V10.5689C64.8174 9.84523 64.5029 9.48536 63.8457 9.48536C63.6969 9.47843 63.5484 9.50375 63.4104 9.55958C63.2723 9.61541 63.148 9.70043 63.0459 9.80882C62.9437 9.9172 62.8662 10.0464 62.8187 10.1875C62.7712 10.3286 62.7547 10.4784 62.7705 10.6265V13.2607H61.8818L61.8818 8.75782Z"
        fill="white"
      />
      <path d="M67.1221 7H68.0107V13.2607H67.1221V7Z" fill="white" />
      <path
        d="M69.2461 11.0073C69.2178 10.7114 69.2517 10.4128 69.3455 10.1307C69.4394 9.84866 69.5912 9.58931 69.7911 9.36932C69.9911 9.14933 70.2348 8.97356 70.5067 8.85329C70.7786 8.73301 71.0726 8.67088 71.3699 8.67088C71.6672 8.67088 71.9612 8.73301 72.233 8.85329C72.5049 8.97356 72.7486 9.14933 72.9486 9.36932C73.1486 9.58931 73.3003 9.84866 73.3942 10.1307C73.4881 10.4128 73.5219 10.7114 73.4936 11.0073C73.5225 11.3036 73.489 11.6026 73.3954 11.8851C73.3017 12.1676 73.15 12.4274 72.95 12.6478C72.75 12.8682 72.5061 13.0444 72.234 13.1649C71.9618 13.2854 71.6675 13.3477 71.3699 13.3477C71.0722 13.3477 70.7779 13.2854 70.5058 13.1649C70.2336 13.0444 69.9897 12.8682 69.7897 12.6478C69.5897 12.4274 69.438 12.1676 69.3444 11.8851C69.2508 11.6026 69.2173 11.3036 69.2461 11.0073ZM72.5791 11.0073C72.5791 10.0313 72.1406 9.46047 71.3711 9.46047C70.5986 9.46047 70.1641 10.0313 70.1641 11.0073C70.1641 11.9912 70.5987 12.5576 71.3711 12.5576C72.1406 12.5576 72.5791 11.9873 72.5791 11.0073Z"
        fill="white"
      />
      <path
        d="M74.4292 11.9873C74.4292 11.1768 75.0327 10.7095 76.104 10.6431L77.3237 10.5728V10.1841C77.3237 9.70851 77.0093 9.43996 76.4019 9.43996C75.9058 9.43996 75.562 9.62209 75.4634 9.94045H74.603C74.6938 9.16701 75.4214 8.67092 76.4429 8.67092C77.5718 8.67092 78.2085 9.23292 78.2085 10.1841V13.2608H77.353V12.6279H77.2827C77.14 12.8549 76.9396 13.04 76.702 13.1642C76.4643 13.2884 76.198 13.3473 75.9302 13.3349C75.7411 13.3546 75.5501 13.3344 75.3693 13.2757C75.1886 13.217 75.0221 13.1211 74.8807 12.9942C74.7393 12.8672 74.626 12.712 74.5483 12.5386C74.4705 12.3652 74.4299 12.1774 74.4292 11.9873ZM77.3237 11.6025V11.2261L76.2241 11.2964C75.604 11.3379 75.3227 11.5488 75.3227 11.9458C75.3227 12.3511 75.6743 12.5869 76.1577 12.5869C76.2994 12.6013 76.4425 12.587 76.5785 12.5449C76.7145 12.5028 76.8406 12.4338 76.9494 12.3419C77.0582 12.2501 77.1474 12.1373 77.2117 12.0103C77.276 11.8832 77.3141 11.7446 77.3237 11.6025Z"
        fill="white"
      />
      <path
        d="M79.3765 11.0073C79.3765 9.58449 80.1079 8.68312 81.2456 8.68312C81.527 8.67015 81.8063 8.73756 82.0508 8.87745C82.2954 9.01735 82.495 9.22395 82.6265 9.47312H82.6929V7H83.5815V13.2607H82.73V12.5493H82.6597C82.5181 12.7968 82.3114 13.0008 82.0622 13.1393C81.8129 13.2778 81.5306 13.3455 81.2456 13.3349C80.1001 13.335 79.3765 12.4336 79.3765 11.0073ZM80.2945 11.0073C80.2945 11.9624 80.7447 12.5371 81.4976 12.5371C82.2466 12.5371 82.7095 11.9541 82.7095 11.0112C82.7095 10.0728 82.2417 9.48146 81.4976 9.48146C80.7495 9.48146 80.2945 10.0601 80.2945 11.0073Z"
        fill="white"
      />
      <path
        d="M87.2583 11.0073C87.23 10.7114 87.2638 10.4129 87.3577 10.1308C87.4515 9.84871 87.6033 9.58937 87.8032 9.36939C88.0032 9.14941 88.2469 8.97364 88.5187 8.85337C88.7906 8.73309 89.0846 8.67096 89.3818 8.67096C89.6791 8.67096 89.9731 8.73309 90.2449 8.85337C90.5168 8.97364 90.7605 9.14941 90.9604 9.36939C91.1604 9.58937 91.3121 9.84871 91.406 10.1308C91.4998 10.4129 91.5337 10.7114 91.5054 11.0073C91.5342 11.3036 91.5008 11.6025 91.4072 11.8851C91.3136 12.1676 91.1619 12.4274 90.9619 12.6478C90.7619 12.8682 90.518 13.0444 90.2459 13.1649C89.9738 13.2854 89.6795 13.3477 89.3818 13.3477C89.0842 13.3477 88.7899 13.2854 88.5178 13.1649C88.2456 13.0444 88.0017 12.8682 87.8017 12.6478C87.6018 12.4274 87.4501 12.1676 87.3565 11.8851C87.2629 11.6025 87.2295 11.3036 87.2583 11.0073ZM90.5913 11.0073C90.5913 10.0313 90.1528 9.46047 89.3833 9.46047C88.6108 9.46047 88.1763 10.0313 88.1763 11.0073C88.1763 11.9912 88.6109 12.5576 89.3833 12.5576C90.1528 12.5576 90.5913 11.9873 90.5913 11.0073Z"
        fill="white"
      />
      <path
        d="M92.6977 8.75782H93.5532V9.47315H93.6196C93.7323 9.21623 93.9223 9.00086 94.1632 8.85705C94.404 8.71324 94.6838 8.64817 94.9634 8.6709C95.1825 8.65443 95.4025 8.68746 95.6071 8.76756C95.8117 8.84765 95.9956 8.97276 96.1453 9.1336C96.295 9.29445 96.4066 9.48691 96.4718 9.69673C96.537 9.90655 96.5541 10.1283 96.522 10.3457V13.2607H95.6333V10.5689C95.6333 9.84523 95.3188 9.48536 94.6616 9.48536C94.5129 9.47843 94.3644 9.50375 94.2263 9.55958C94.0882 9.61541 93.9639 9.70043 93.8618 9.80882C93.7596 9.9172 93.6822 10.0464 93.6346 10.1875C93.5871 10.3286 93.5707 10.4784 93.5864 10.6265V13.2607H92.6977V8.75782Z"
        fill="white"
      />
      <path
        d="M101.543 7.63673V8.77833H102.519V9.52687H101.543V11.8423C101.543 12.314 101.738 12.5205 102.18 12.5205C102.293 12.5202 102.407 12.5133 102.519 12.5V13.2402C102.359 13.2688 102.198 13.284 102.036 13.2856C101.047 13.2856 100.654 12.938 100.654 12.0698V9.52683H99.939V8.77829H100.654V7.63673H101.543Z"
        fill="white"
      />
      <path
        d="M103.733 7H104.614V9.48145H104.684C104.802 9.22213 104.997 9.00554 105.243 8.86119C105.489 8.71684 105.773 8.65176 106.057 8.67481C106.275 8.66295 106.493 8.69934 106.695 8.78138C106.897 8.86342 107.079 8.9891 107.227 9.14943C107.375 9.30977 107.486 9.50082 107.552 9.70896C107.618 9.91709 107.637 10.1372 107.608 10.3535V13.2607H106.718V10.5728C106.718 9.85351 106.383 9.48925 105.755 9.48925C105.603 9.47672 105.449 9.49772 105.305 9.55077C105.161 9.60383 105.031 9.68766 104.923 9.79639C104.815 9.90513 104.732 10.0362 104.68 10.1803C104.628 10.3244 104.608 10.4782 104.622 10.6308V13.2607H103.733L103.733 7Z"
        fill="white"
      />
      <path
        d="M112.79 12.0449C112.669 12.4565 112.407 12.8125 112.051 13.0506C111.694 13.2888 111.265 13.3939 110.838 13.3477C110.542 13.3555 110.247 13.2987 109.974 13.1811C109.702 13.0636 109.458 12.8882 109.26 12.6671C109.062 12.4459 108.915 12.1844 108.828 11.9005C108.742 11.6167 108.718 11.3174 108.758 11.0234C108.719 10.7286 108.743 10.4286 108.83 10.144C108.916 9.85929 109.063 9.59652 109.26 9.37345C109.456 9.15037 109.699 8.9722 109.971 8.85099C110.242 8.72979 110.537 8.66837 110.834 8.67091C112.087 8.67091 112.843 9.52691 112.843 10.9409V11.251H109.664V11.3008C109.65 11.466 109.67 11.6324 109.725 11.7891C109.779 11.9459 109.865 12.0896 109.978 12.211C110.091 12.3325 110.228 12.429 110.38 12.4944C110.533 12.5598 110.697 12.5926 110.863 12.5908C111.075 12.6163 111.291 12.578 111.481 12.4808C111.672 12.3836 111.83 12.2319 111.934 12.0449L112.79 12.0449ZM109.664 10.5938H111.938C111.949 10.4426 111.929 10.2908 111.878 10.1481C111.827 10.0054 111.746 9.87492 111.642 9.76506C111.538 9.6552 111.411 9.5684 111.272 9.51022C111.132 9.45205 110.981 9.42379 110.83 9.42726C110.676 9.42533 110.523 9.45419 110.381 9.51213C110.239 9.57008 110.109 9.65594 110.001 9.76465C109.892 9.87336 109.806 10.0027 109.748 10.1451C109.69 10.2875 109.662 10.4401 109.664 10.5938Z"
        fill="white"
      />
    </svg>
  );
});
