import {Palette} from 'hubbl-shared';
import {memo} from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import assetAppLoadingAnimation from '../../assets/animations/app-loading/assetAppLoadingAnimation';
import {useAppSelector} from '../../hooks';
import {GlobalSelector} from '../../store/global/GlobalSelector';

export const LoadingAnimation = memo(() => {
  const isBusy = useAppSelector(GlobalSelector.getIsBusy);

  if (!isBusy) {
    return null;
  }

  return (
    <Overlay>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: assetAppLoadingAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={100}
        width={100}
      />
    </Overlay>
  );
});

// Styled Component for the overlay
const Overlay = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Palette.whiteTransparent60};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensures it's on top of other content */

  /* Fade-in animation */
  animation: fadeIn 0.3s ease;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
