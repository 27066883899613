import {Palette} from 'hubbl-shared';
import {memo} from 'react';
import styled from 'styled-components';
import {TextHeading5} from '../../text/TextHeading5';

const Button = styled.button`
  background-color: ${Palette.almostBlack};
  color: ${Palette.white};
  height: 48px;
  width: 180px;
  border-radius: 20px;
  border-width: 0px;
  outline: 0;
  cursor: pointer;
  transition:
    ease background-color 250ms,
    transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

interface PrimaryButtonProps {
  onClick: () => void;
  label: string;
}

export const PrimaryButton = memo(({label, onClick}: PrimaryButtonProps) => {
  return (
    <Button onClick={onClick}>
      <TextHeading5 text={label} color={Palette.white} />
    </Button>
  );
});
