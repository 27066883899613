import React from 'react';
import {Wizard} from 'react-use-wizard';

import {AnimatePresence} from 'framer-motion';
import {Step1} from './components/steps/step1/Step1';
import {Step2} from './components/steps/step2/Step2';
import {StepIndicator} from './components/step-indicator/StepIndicator';
import {Step3} from './components/steps/step3/Step3';
export const GetStartedWizard = () => {
  const previousStep = React.useRef<number>(0);

  return (
    <Wizard
      header={<StepIndicator />}
      wrapper={<AnimatePresence initial={false} mode="wait" />}>
      <Step1 previousStep={previousStep} />
      <Step2 previousStep={previousStep} />
      <Step3 previousStep={previousStep} />
    </Wizard>
  );
};
