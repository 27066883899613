import {createSlice} from '@reduxjs/toolkit';
import {setIsBusy} from './GlobalThunks';

interface GlobalState {
  isBusy: boolean;
}

const initialState: GlobalState = {
  isBusy: false,
};

export const globalSlice = createSlice({
  name: 'get-started',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setIsBusy.fulfilled, (state, action) => {
      state.isBusy = action.payload;
    });
  },
});

export const {} = globalSlice.actions;

export const GlobalReducer = globalSlice.reducer;
