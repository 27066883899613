import {getCoolEmoji, Palette} from 'hubbl-shared';
import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {AssetIconCopy} from '../../../assets';
import {TextHeading4} from '../../text/TextHeading4';
import {IconButton} from '../icon-button/IconButton';
import {RWebShare} from 'react-web-share';

interface ClipboardButtonProps {
  value: string;
  useShareAPI?: boolean;
  initialIconComponent?: ReactElement;
  initialText?: string;
  copiedText?: string;
}

export const ClipboardButton = memo(
  ({
    value,
    useShareAPI = false,
    initialIconComponent = <AssetIconCopy />,
    initialText = 'Copy',
    copiedText = 'Copied',
  }: ClipboardButtonProps) => {
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
      if (isCopied) {
        setTimeout(() => setIsCopied(false), 2000);
      }
    }, [isCopied]);

    const onCopy = useCallback(
      () => (isCopied ? null : setIsCopied(true)),
      [isCopied],
    );

    const shareText = useMemo(
      () => `Join my new Hubbl space! ${getCoolEmoji()}`,
      [],
    );

    const iconButtonComponent = useMemo(
      () => (
        <IconButton
          iconPadding={6}
          backgroundColor={
            isCopied ? Palette.white : Palette.whiteTransparent60
          }
          hoverColor={Palette.white}
          icon={isCopied ? <TextHeading4 text="✔️" /> : initialIconComponent}
          text={isCopied ? copiedText : initialText}
          onClick={onCopy}
        />
      ),
      [copiedText, initialIconComponent, initialText, isCopied, onCopy],
    );

    if (useShareAPI) {
      return (
        <RWebShare
          data={{
            text: shareText,
            url: value,
            title: shareText,
          }}
          onClick={() => {}}>
          {iconButtonComponent}
        </RWebShare>
      );
    }

    return (
      <CopyToClipboard text={value}>{iconButtonComponent}</CopyToClipboard>
    );
  },
);
