import {createSlice} from '@reduxjs/toolkit';
import {CompanyPaymentPlan, CompanyType, EntryPoint} from 'hubbl-shared';
import {
  setEntryPoint,
  setGetStartedCompanyType,
  setSelectedActivities,
  setSelectedPaymentPlan,
  setWrittenCompanyName,
  setWrittenEmail,
  setWrittenPhonenumber,
} from './GetStartedThunks';

interface GetStartedState {
  selectedCompanyType: CompanyType;
  writtenCompanyName: string;
  writtenEmail: string;
  writtenPhonenumber: string;
  selectedActivities: string[];
  selectedPaymentPlan: CompanyPaymentPlan;
  entryPoint: EntryPoint;
}

const initialState: GetStartedState = {
  selectedCompanyType: 'company',
  writtenCompanyName: '',
  writtenEmail: '',
  writtenPhonenumber: '',
  selectedActivities: [],
  selectedPaymentPlan: 'free',
  entryPoint: 'desktop',
};

export const getStartedSlice = createSlice({
  name: 'get-started',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setGetStartedCompanyType.fulfilled, (state, action) => {
      state.selectedCompanyType = action.payload;
    });
    builder.addCase(setWrittenCompanyName.fulfilled, (state, action) => {
      state.writtenCompanyName = action.payload;
    });
    builder.addCase(setWrittenEmail.fulfilled, (state, action) => {
      state.writtenEmail = action.payload;
    });
    builder.addCase(setWrittenPhonenumber.fulfilled, (state, action) => {
      state.writtenPhonenumber = action.payload;
    });
    builder.addCase(setSelectedActivities.fulfilled, (state, action) => {
      state.selectedActivities = action.payload;
    });
    builder.addCase(setSelectedPaymentPlan.fulfilled, (state, action) => {
      state.selectedPaymentPlan = action.payload;
    });
    builder.addCase(setEntryPoint.fulfilled, (state, action) => {
      state.entryPoint = action.payload;
    });
  },
});

export const {} = getStartedSlice.actions;

export const GetStartedReducer = getStartedSlice.reducer;
