import {CompanyType, Palette} from 'hubbl-shared';
import {memo, useCallback} from 'react';
import {
  PaddingBottom,
  SelectablePill,
  TextHeading1,
} from '../../../../../../../components';
import {useAppDispatch, useAppSelector} from '../../../../../../../hooks';
import {GetStartedActions} from '../../../../../../../store/get-started/GetStartedActions';
import {GetStartedSelector} from '../../../../../../../store/get-started/GetStartedSelector';
import {
  GetStartedWizardStepContainer,
  GetStartedWizardStepContainerProps,
} from '../../step-container/GetStartedWizardStepContainer';
import {GetStartedWizardFooter} from '../../footer/GetStartedWizardFooter';

export const Step1 = memo(
  ({previousStep}: GetStartedWizardStepContainerProps) => {
    const selectedCompanyType = useAppSelector(
      GetStartedSelector.getSelectedCompanyType,
    );

    const dispatch = useAppDispatch();

    const onSelectCompanyType = useCallback(
      (newCompanyType: CompanyType) => {
        dispatch(GetStartedActions.setGetStartedCompanyType(newCompanyType));
      },
      [dispatch],
    );
    //Where do you want to use hubbl?
    return (
      <GetStartedWizardStepContainer previousStep={previousStep}>
        <TextHeading1
          center
          text="What describes your situation the best?"></TextHeading1>
        <PaddingBottom amount={48} />
        <SelectablePill
          emoji="💼"
          label="I want to use Hubbl within my workplace"
          isSelected={selectedCompanyType === 'company'}
          onClick={() => onSelectCompanyType('company')}
          defaultColor={Palette.white}
          selectedColor={Palette.primaryPadua}
        />
        <SelectablePill
          isSelected={selectedCompanyType === 'coworkingSpace'}
          onClick={() => onSelectCompanyType('coworkingSpace')}
          emoji="👨‍💻"
          label="I manage a coworking space and want to use Hubbl with the coworkers"
          defaultColor={Palette.white}
          selectedColor={Palette.primaryCherokee}
        />
        {/* <SelectablePill
          isSelected={selectedCompanyType === 'friendGroup'}
          onClick={() => onSelectCompanyType('friendGroup')}
          emoji="🧑‍🧒‍🧒"
          label="I want to use Hubbl for me and my friend group"
          defaultColor={Palette.white}
          selectedColor={Palette.primaryPadua}
        /> */}
        <SelectablePill
          isSelected={selectedCompanyType === 'university'}
          onClick={() => onSelectCompanyType('university')}
          emoji="🎓"
          label="I want to use Hubbl within my university"
          defaultColor={Palette.white}
          selectedColor={Palette.primaryDustySky}
        />
        <SelectablePill
          isSelected={selectedCompanyType === 'incubator'}
          onClick={() => onSelectCompanyType('incubator')}
          emoji="🐣"
          label="I manage an incubator and want to use Hubbl amongst the start-ups"
          defaultColor={Palette.white}
          selectedColor={Palette.primaryTerraCotta}
        />
        <GetStartedWizardFooter isBackHidden />
      </GetStartedWizardStepContainer>
    );
  },
);
