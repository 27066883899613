import {User} from 'hubbl-shared';
import {UserDTO} from '../../../models';
import {Timestamp} from 'firebase/firestore';

export const mapUserToUserDTO = (user: User): UserDTO => {
  return {
    ...user,
    startedAtCompany: user.startedAtCompany
      ? Timestamp.fromDate(new Date(user.startedAtCompany))
      : null,
    connectedHRISInfo: user.connectedHRISInfo
      ? {
          ...user.connectedHRISInfo,
          birthday: user.connectedHRISInfo.birthday
            ? Timestamp.fromDate(new Date(user.connectedHRISInfo.birthday))
            : null,
        }
      : null,
  };
};
