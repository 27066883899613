import {
  ClipboardButton,
  PaddingBottom,
  PrimaryButton,
  Scene,
  SecondaryButton,
  TextBodyLarge,
  TextDisplayLarge,
  TextHeading1,
  TextHeading3,
} from '../../components';

import {getCoolEmoji} from 'hubbl-shared';
import {useCallback, useEffect, useMemo} from 'react';
import {BrowserView, isDesktop, MobileView} from 'react-device-detect';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {
  AssetAppleAppStoreButton,
  AssetGooglePlayButton,
  AssetIconShare,
} from '../../assets';
import appDownloadQRImage from '../../assets/images/appDownloadQR.png';
import getStartedBackgroundImage from '../../assets/images/getStartedBackgroundImage.png';
import {RootRoute} from '../../routes/RootRoute';
import {useAppSelector} from '../../hooks';
import {GetStartedSelector} from '../../store/get-started/GetStartedSelector';

export const SceneGetStartedCompleted = () => {
  const {state} = useLocation();

  const navigate = useNavigate();

  const onBackToHubbl = useCallback(
    () => window.open('https://hubbl.co', '_self'),
    [],
  );
  useEffect(() => {
    if (!state || !state?.pincode || !state?.companyName) {
      navigate(RootRoute.GetStarted, {replace: true});
    }
  }, [navigate, state]);

  const emoji = useMemo(() => getCoolEmoji(), []);

  const baseUrl = process.env.REACT_APP_DEPLOYED_URL;

  const entryPoint = useAppSelector(GetStartedSelector.getEntryPoint);

  if (!state || !state?.pincode || !state?.companyName || !baseUrl) {
    return null;
  }

  const shareUrl = `${baseUrl}invited?pin=${state.pincode}&space=${state.companyName}`;

  return (
    <div
      style={{
        backgroundImage: `url(${getStartedBackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
      }}>
      <Scene>
        <Container>
          <TextHeading1 text={`Let's go! ${emoji}`} />
          <PaddingBottom amount={24} />
          <div style={{width: 'auto', height: 'auto'}}>
            <TextBodyLarge
              center
              text={
                entryPoint === 'app'
                  ? 'We have successfully created your community. You can now use below pincode'
                  : 'You can now download Hubbl on your phone and use the pincode below to get started'
                //"Download the app by searching 'Hubbl' in app store or scan below"
              }
            />
            <PaddingBottom amount={12} />
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <TextHeading3 center text={'Your pincode: '} />
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  gap: 16,
                  paddingLeft: isDesktop ? 48 : 0,
                  alignItems: 'center',
                }}>
                <TextHeading1 center text={state.pincode ?? ''} />

                {entryPoint === 'app' ? (
                  <ClipboardButton useShareAPI={false} value={state.pincode} />
                ) : (
                  <ClipboardButton
                    copiedText="Link copied to clipboard"
                    initialText="Share"
                    useShareAPI={isDesktop ? false : true}
                    initialIconComponent={<AssetIconShare />}
                    value={shareUrl}
                  />
                )}
              </div>
            </div>
          </div>
          <PaddingBottom amount={24} />
          <BrowserView>
            <div style={{position: 'relative', display: 'inline-block'}}>
              <img
                src={appDownloadQRImage}
                alt="qr code"
                height={'100%'}
                width={200}
              />
              <div
                style={{
                  position: 'absolute',
                  left: 170,
                  width: 100,
                  top: -20,
                  rotate: '20deg',
                }}>
                <TextDisplayLarge center text="Scan to download app" />
              </div>
            </div>
          </BrowserView>
          <PaddingBottom amount={24} />
          <BrowserView>
            <div style={{flexDirection: 'row', display: 'flex', gap: 16}}>
              <Linkbutton
                target="_blank"
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/hubbl-connecting-colleagues/id1661817144">
                <AssetAppleAppStoreButton />
              </Linkbutton>
              <Linkbutton
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.emilstepanian.workbreak">
                <AssetGooglePlayButton />
              </Linkbutton>
            </div>
          </BrowserView>
          <MobileView>
            {entryPoint !== 'app' && (
              <Linkbutton
                target="_blank"
                rel="noopener noreferrer"
                href="https://onelink.to/68d4hd">
                <PrimaryButton label="Download app" onClick={() => {}} />
              </Linkbutton>
            )}
          </MobileView>
          {entryPoint !== 'app' && (
            <>
              <PaddingBottom amount={16} />
              <SecondaryButton label="Go to hubbl.co" onClick={onBackToHubbl} />
            </>
          )}
        </Container>
      </Scene>
    </div>
  );
};

const Container = styled.div`
  height: 100%;
  flex: 1;
  padding: 2.75rem 0.35rem;
  display: flex;
  flex-direction: column;
  min-height: 15vh;
  justify-content: center;
  align-items: center;
`;

const Linkbutton = styled.a`
  cursor: pointer;
  transition:
    ease background-color 250ms,
    transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;
