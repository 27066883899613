import {Palette} from 'hubbl-shared';
import {Fragment, memo} from 'react';
import {PaddingBottom, TextHeading9} from '..';

interface FieldErrorProps {
  hasError?: boolean;
  errorMessage: string;
}

export const FieldError = memo(({hasError, errorMessage}: FieldErrorProps) => {
  return hasError ? (
    <Fragment>
      <PaddingBottom amount={6} />
      <TextHeading9 color={Palette.systemError} text={errorMessage} />
    </Fragment>
  ) : null;
});
