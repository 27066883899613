import React, {memo, useEffect, useState} from 'react';
import styled from 'styled-components';
import {motion} from 'framer-motion';
import {Palette} from 'hubbl-shared';

interface RadioButtonCircleProps {
  isSelected: boolean;
}

export const RadioButtonCircle = memo(
  ({isSelected}: RadioButtonCircleProps) => {
    const [scale, setScale] = useState(0);

    useEffect(() => {
      setScale(isSelected ? 1 : 0);
    }, [isSelected]);

    return (
      <CircleContainer>
        <motion.div
          animate={{scale}}
          transition={{type: 'spring', stiffness: 300, damping: 20}}
          style={selectedCircleStyles}
        />
      </CircleContainer>
    );
  },
);

const CircleContainer = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid ${Palette.almostBlack};
  background-color: ${Palette.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const selectedCircleStyles: React.CSSProperties = {
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: Palette.almostBlack,
};
