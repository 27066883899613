import {collection, doc, getDoc, getDocs, updateDoc} from 'firebase/firestore';
import {
  Company,
  CreateCompanyFromGetStartedWebDataProps,
  FirestoreCollection,
  HRIS,
  User,
} from 'hubbl-shared';
import {CompanyDTO} from '../models';
import {firebaseFunctions, firestore} from './firebase';
import {mapCompanyDTOToCompany} from './utils/map-incoming-company/mapCompanyDTOToCompany';
import {mapIncomingUsers} from './utils/map-incoming-users/mapIncomingUsers';
import {httpsCallable} from 'firebase/functions';

const getLoggedInUserCompany = async (
  companyId: string,
): Promise<Company | null> => {
  const companyPromise = new Promise<Company | null>(
    async (resolve, reject) => {
      try {
        const companyRef = doc(
          firestore,
          FirestoreCollection.Companies,
          companyId,
        );
        const companySnapshot = await getDoc(companyRef);

        if (companySnapshot.exists()) {
          //TODO: PARSE CREATED AT LIKE IN APP
          resolve(mapCompanyDTOToCompany(companySnapshot.data() as CompanyDTO));
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(error);
      }
    },
  );
  return companyPromise;
};

const getCompanyUsers = async (companyId: string): Promise<User[]> => {
  const usersPromise = new Promise<User[]>(async (resolve, reject) => {
    try {
      const companyRef = doc(
        firestore,
        FirestoreCollection.Companies,
        companyId,
      );
      const usersRef = collection(companyRef, FirestoreCollection.Users);
      const users = await getDocs(usersRef);
      //TODO: PARSE CREATED AT LIKE IN APP

      resolve(mapIncomingUsers(users.docs));
    } catch (error) {
      reject(error);
    }
  });
  return usersPromise;
};

const addHRIStoCompany = async (companyId: string, hris: HRIS) => {
  const companyPromise = new Promise<void>(async (resolve, reject) => {
    try {
      const companyRef = doc(
        firestore,
        FirestoreCollection.Companies,
        companyId,
      );

      await updateDoc(companyRef, {'settings.connectedHRIS': hris});

      resolve();
    } catch (error) {
      reject(error);
    }
  });
  return companyPromise;
};

const createCompany = async (
  dataProps: CreateCompanyFromGetStartedWebDataProps,
) => {
  const callableFunc = httpsCallable(
    firebaseFunctions,
    'onCompanyCreatedFromGetStartedWeb',
  );
  return callableFunc(dataProps)
    .then(result => {
      console.log(result.data);
      return result.data as {pincode: string};
    })
    .catch(error => {
      console.log(`error: ${JSON.stringify(error)}`);
      throw error;
    });
};

export const company = {
  getCompanyUsers,
  addHRIStoCompany,
  getLoggedInUserCompany,
  createCompany,
};
