import {Palette} from 'hubbl-shared';
import {memo} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {FontConfig} from '../../../../../../../../../constants/FontConfig';
import './PhoneNumberInput.css';
interface PhoneNumberInputProps {
  value: string;
  onChange: (newVal: string) => void;
}

export const PhoneNumberInput = memo(
  ({value, onChange}: PhoneNumberInputProps) => {
    return (
      <PhoneInput
        containerStyle={{
          backgroundColor: Palette.transparent,
          height: 60,
          alignItems: 'center',
          borderRadius: 16,
          borderWidth: 0,
        }}
        inputStyle={{
          borderWidth: 1,
          borderColor: Palette.white,
          backgroundColor: Palette.whiteTransparent60,
          borderRadius: 24,
          height: 60,
          alignItems: 'center',
          fontFamily: FontConfig.Heading3.fontFamily,
          fontSize: FontConfig.Heading3.fontSize,
          fontWeight: FontConfig.Heading3.fontWeight,
          color: Palette.almostBlack,
        }}
        buttonStyle={{
          backgroundColor: Palette.whiteTransparent60,
          borderTopLeftRadius: 24,
          borderBottomLeftRadius: 24,
          borderWidth: 0,
        }}
        searchStyle={{
          backgroundColor: Palette.white,
          height: 32,
          borderRadius: 16,
          paddingLeft: 12,
          width: '85%',
          borderColor: Palette.primaryBianca,
          fontFamily: FontConfig.Heading6.fontFamily,
          fontSize: FontConfig.Heading6.fontSize,
          fontWeight: FontConfig.Heading6.fontWeight,
        }}
        dropdownStyle={{
          backgroundColor: Palette.white,
          scrollbarWidth: 'none',
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 15px',
          borderRadius: 24,
          marginTop: 8,
          fontFamily: FontConfig.Heading5.fontFamily,
          fontSize: FontConfig.Heading5.fontSize,
          fontWeight: FontConfig.Heading5.fontWeight,
        }}
        enableSearch
        country={'dk'}
        value={value}
        onChange={onChange}
      />
    );
  },
);
