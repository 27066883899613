import {Palette} from 'hubbl-shared';
import {memo, useEffect, useState} from 'react';
import styled from 'styled-components';
import {TextHeading4} from '../text/TextHeading4';
import {RadioButtonCircle} from './components/radio-button-circle/RadioButtonCircle';

interface SelectablePillProps {
  label: string;
  emoji?: string;
  selectedColor: Palette;
  defaultColor?: Palette;
  selectedTextColor?: Palette;
  onClick: () => void;
  isSelected: boolean;
}

export const SelectablePill = memo(
  ({
    label,
    selectedColor = Palette.primarySubtleGreen,
    emoji = '',
    onClick,
    defaultColor = Palette.white,
    selectedTextColor,
    isSelected,
  }: SelectablePillProps) => {
    const [textWidth, setTextWidth] = useState<string | number | undefined>(
      window.innerWidth < 780 ? 220 : 400,
    );

    useEffect(() => {
      function handleResize() {
        // Update the state or perform any other actions when the
        // browser is resized
        setTextWidth(window.innerWidth < 780 ? 220 : 400);
      }

      // Attach the event listener to the window object
      window.addEventListener('resize', handleResize);

      // Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return (
      <SelectablePillButton
        isSelected={isSelected}
        selectedColor={selectedColor}
        defaultColor={defaultColor}
        onClick={onClick}>
        <PillContent>
          <RadioButtonCircle isSelected={isSelected} />
          <TextHeading4
            style={{
              width: textWidth,
            }}
            color={isSelected ? selectedTextColor : undefined}
            text={label}
          />
          <TextHeading4
            color={isSelected ? selectedTextColor : undefined}
            text={emoji}
          />
        </PillContent>
      </SelectablePillButton>
    );
  },
);

const PillContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center; /* Aligns radio button and text on the same line */
  gap: 8px; /* Adds space between the radio button and the text */
`;

const SelectablePillButton = styled.button<{
  isSelected: boolean;
  selectedColor: Palette;
  defaultColor: Palette;
}>`
  display: flex;
  background-color: ${({isSelected, selectedColor, defaultColor}) =>
    isSelected ? selectedColor : defaultColor};

  -webkit-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  justify-content: flex-start; /* Adjusts alignment to start, ensuring dynamic height */
  align-items: center; /* Keeps items aligned properly */
  /* Handle word wrapping */
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  margin: 4px;
  padding: 16px; /* Combined padding for consistency */
  border-width: 0px;
  outline: 0;
  cursor: pointer;
  transition:
    background-color 250ms ease,
    box-shadow 250ms ease,
    transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`;
