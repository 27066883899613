import {
  ClipboardButton,
  PaddingBottom,
  Scene,
  TextBodyLarge,
  TextDisplayLarge,
  TextHeading1,
  TextHeading3,
} from '../../components';

import {getCoolEmoji} from 'hubbl-shared';
import {useEffect, useMemo, useState} from 'react';
import {BrowserView, isDesktop} from 'react-device-detect';
import {useNavigate, useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {AssetAppleAppStoreButton, AssetGooglePlayButton} from '../../assets';
import appDownloadQRImage from '../../assets/images/appDownloadQR.png';
import getStartedBackgroundImage from '../../assets/images/getStartedBackgroundImage.png';

export const SceneInvited = () => {
  const [searchParams] = useSearchParams();
  const pin = searchParams.get('pin');
  const spaceName = searchParams.get('space');

  const navigate = useNavigate();

  useEffect(() => {
    if (!pin || !spaceName) {
      navigate('404', {replace: true});
    }
  }, [navigate, pin, spaceName]);

  const [isCopied, setIsCopied] = useState(false);
  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 2000);
    }
  }, [isCopied]);

  const emoji = useMemo(() => getCoolEmoji(), []);

  return (
    <div
      style={{
        backgroundImage: `url(${getStartedBackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
      }}>
      <Scene>
        <Container>
          <TextHeading1 center text={`You are invited! ${emoji}`} />
          <PaddingBottom amount={24} />
          <div style={{width: 'auto', height: 'auto'}}>
            <TextBodyLarge
              center
              text={`You have been invited to join ${spaceName}'s Hubbl space! Download the app and get started with pincode below.`}
            />
            <PaddingBottom amount={12} />
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <TextHeading3 center text={'Your pincode: '} />
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  gap: 16,
                  paddingLeft: isDesktop ? 48 : 0,
                  alignItems: 'center',
                }}>
                <TextHeading1 center text={pin ?? ''} />
                <ClipboardButton value={pin ?? ''} />
              </div>
            </div>
          </div>
          <PaddingBottom amount={24} />
          <BrowserView>
            <div style={{position: 'relative', display: 'inline-block'}}>
              <img
                src={appDownloadQRImage}
                alt="qr code"
                height={'100%'}
                width={200}
              />
              <div
                style={{
                  position: 'absolute',
                  left: 170,
                  width: 100,
                  top: -20,
                  rotate: '20deg',
                }}>
                <TextDisplayLarge center text="Scan to download app" />
              </div>
            </div>
          </BrowserView>
          <PaddingBottom amount={24} />
          <div style={{flexDirection: 'row', display: 'flex', gap: 16}}>
            <Linkbutton
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/hubbl-connecting-colleagues/id1661817144">
              <AssetAppleAppStoreButton />
            </Linkbutton>
            <Linkbutton
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.emilstepanian.workbreak">
              <AssetGooglePlayButton />
            </Linkbutton>
          </div>
        </Container>
      </Scene>
    </div>
  );
};

const Container = styled.div`
  height: 100%;
  flex: 1;
  padding: 2.75rem 0.35rem;
  display: flex;
  flex-direction: column;
  min-height: 15vh;
  justify-content: center;
  align-items: center;
`;

const Linkbutton = styled.a`
  cursor: pointer;
  transition:
    ease background-color 250ms,
    transform 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;
