import {memo, useEffect, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useWizard} from 'react-use-wizard';
import {
  ManagedInput,
  PaddingBottom,
  TextHeading1,
} from '../../../../../../../components';
import {useAppDispatch, useAppSelector} from '../../../../../../../hooks';
import {GetStartedActions} from '../../../../../../../store/get-started/GetStartedActions';
import {GetStartedSelector} from '../../../../../../../store/get-started/GetStartedSelector';
import {GetStartedWizardFooter} from '../../footer/GetStartedWizardFooter';
import {
  GetStartedWizardStepContainer,
  GetStartedWizardStepContainerProps,
} from '../../step-container/GetStartedWizardStepContainer';

const COMPANY_NAME_FIELD = 'COMPANY_NAME_FIELD';

export const Step2 = memo(
  ({previousStep}: GetStartedWizardStepContainerProps) => {
    const selectedCompanyType = useAppSelector(
      GetStartedSelector.getSelectedCompanyType,
    );

    const {control, handleSubmit, setValue} = useForm();

    const writtenCompanyName = useAppSelector(
      GetStartedSelector.getWrittenCompanyName,
    );

    useEffect(() => {
      setValue(COMPANY_NAME_FIELD, writtenCompanyName);
    }, [setValue, writtenCompanyName]);

    const {nextStep} = useWizard();

    const dispatch = useAppDispatch();

    const onSubmit = (data: any) => {
      const newWrittenCompanyName = data[COMPANY_NAME_FIELD] ?? ('' as string);

      dispatch(GetStartedActions.setWrittenCompanyName(newWrittenCompanyName));
      nextStep();
    };

    const titleText = useMemo(() => {
      switch (selectedCompanyType) {
        default:
        case 'company':
          return 'What is the name of the company you work at?';

        case 'coworkingSpace':
          return 'What is the name of your coworking space?';

        case 'dormitory':
          return 'What is the name of your dormitory?';

        case 'friendGroup':
          return 'Give your friend group a name!';

        case 'incubator':
          return 'What is the name of the incubator?';

        case 'university':
          return 'What is the name of your university?';
      }
    }, [selectedCompanyType]);
    const placeholderText = useMemo(() => {
      switch (selectedCompanyType) {
        default:
        case 'company':
          return 'My Company';

        case 'coworkingSpace':
          return 'My Coworking Space';

        case 'dormitory':
          return 'My Dormitory';

        case 'friendGroup':
          return 'My fun friend group';

        case 'incubator':
          return 'Accelerace';

        case 'university':
          return 'My University';
      }
    }, [selectedCompanyType]);

    return (
      <GetStartedWizardStepContainer previousStep={previousStep}>
        <TextHeading1 center text={titleText}></TextHeading1>
        <PaddingBottom amount={40}></PaddingBottom>
        <form onBlur={handleSubmit(onSubmit)} onSubmit={handleSubmit(onSubmit)}>
          <ManagedInput
            placeholder={`${placeholderText}...`}
            control={control}
            controlKey={COMPANY_NAME_FIELD}
            isRequiredMessage={'Cannot be left blank'}
            pattern={{
              message: 'Not a valid name',
              value: RegExp(
                /^[\w'\-,.][^_!¡?÷¿/\\+=@#$%^&*(){}|~<>;:[\]]{2,}$/,
              ),
            }}
          />
        </form>
        <GetStartedWizardFooter onCustomNextStep={handleSubmit(onSubmit)} />
      </GetStartedWizardStepContainer>
    );
  },
);
