import {createRef, memo, useEffect, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {useForm} from 'react-hook-form';
import {
  ManagedInput,
  PaddingBottom,
  TextHeading1,
  TextHeading6,
} from '../../../../../../../components';
import {useAppDispatch, useAppSelector} from '../../../../../../../hooks';
import {GetStartedActions} from '../../../../../../../store/get-started/GetStartedActions';
import {GetStartedSelector} from '../../../../../../../store/get-started/GetStartedSelector';
import {GlobalActions} from '../../../../../../../store/global/GlobalActions';
import {GetStartedWizardFooter} from '../../footer/GetStartedWizardFooter';
import {
  GetStartedWizardStepContainer,
  GetStartedWizardStepContainerProps,
} from '../../step-container/GetStartedWizardStepContainer';

import {useNavigate} from 'react-router-dom';
import {RootRoute} from '../../../../../../../routes/RootRoute';
import {PhoneNumberInput} from './components/phone-number-input/PhoneNumberInput';

const EMAIL_FIELD = 'EMAIL_FIELD';
const PHONENUMBER_FIELD = 'PHONENUMBER_FIELD';

export const Step3 = memo(
  ({previousStep}: GetStartedWizardStepContainerProps) => {
    const {control, handleSubmit, setValue, getValues} = useForm();

    const writtenEmail = useAppSelector(GetStartedSelector.getWrittenEmail);

    useEffect(() => {
      setValue(EMAIL_FIELD, writtenEmail);
    }, [setValue, writtenEmail]);

    const dispatch = useAppDispatch();
    const recaptchaRef = createRef<ReCAPTCHA>();
    const navigate = useNavigate();

    const [isButtonEnabled, setIsButtonEnabled] = useState(true);

    const onSubmit = async (data: any) => {
      try {
        dispatch(GlobalActions.setIsBusy(true));
        setIsButtonEnabled(false);

        const token = await recaptchaRef.current?.executeAsync();

        if (!token) {
          //TODO: ADD ERROR HANDLING HERE
          return null;
        }
        const writtenPhonenumber = data[PHONENUMBER_FIELD] ?? ('' as string);

        const newWrittenEmail = data[EMAIL_FIELD] ?? ('' as string);

        await dispatch(GetStartedActions.setWrittenEmail(newWrittenEmail));
        await dispatch(
          GetStartedActions.setWrittenPhonenumber(writtenPhonenumber),
        );

        const {pincode, writtenCompanyName} = await dispatch(
          GetStartedActions.createCompany(token),
        )
          .unwrap()
          .catch(error => {
            //TODO: ERROR HAPPENED
            console.log(error);
            throw error;
          });

        navigate(RootRoute.GetStartedCompleted + `?pincode=${pincode}`, {
          replace: true,
          state: {pincode, companyName: writtenCompanyName},
        });

        setIsButtonEnabled(true);
      } catch (error) {
      } finally {
        dispatch(GlobalActions.setIsBusy(false));
      }
    };

    return (
      <GetStartedWizardStepContainer previousStep={previousStep}>
        <TextHeading1 text="Almost there!"></TextHeading1>
        <PaddingBottom amount={40}></PaddingBottom>
        <form>
          <TextHeading6 text={'Your email:'} />
          <PaddingBottom amount={8} />
          <ManagedInput
            control={control}
            placeholder="myname@email.com"
            controlKey={EMAIL_FIELD}
            isRequiredMessage={'Cannot be left blank'}
            pattern={{
              message: 'Not a valid email',
              value: RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
            }}
          />
          <PaddingBottom amount={24} />
          <TextHeading6 text={'Your phone number:'} />
          <PaddingBottom amount={8} />

          <PhoneNumberInput
            value={getValues()[PHONENUMBER_FIELD]}
            onChange={phone => setValue(PHONENUMBER_FIELD, phone)}
          />
          {process.env.REACT_APP_RECAPTCHA_KEY && (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              size="invisible"
            />
          )}
        </form>
        {isButtonEnabled && (
          <GetStartedWizardFooter onCustomNextStep={handleSubmit(onSubmit)} />
        )}
      </GetStartedWizardStepContainer>
    );
  },
);
