import {motion, Variants} from 'framer-motion';
import {memo, PropsWithChildren, useEffect} from 'react';
import {useWizard} from 'react-use-wizard';
import styled from 'styled-components';
export type GetStartedWizardStepContainerProps = PropsWithChildren & {
  previousStep: React.MutableRefObject<number>;
};

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 800 : -800,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 800 : -800,
      opacity: 0,
    };
  },
};

const Container = styled.div`
  height: 100%;
  flex: 1;
  padding: 2.75rem 0.35rem;
  display: flex;
  flex-direction: column;
  min-height: 15vh;
  justify-content: center;
  align-items: center;
`;

export const GetStartedWizardStepContainer = memo(
  ({children, previousStep}: GetStartedWizardStepContainerProps) => {
    const {activeStep} = useWizard();
    useEffect(() => {
      return () => {
        previousStep.current = activeStep;
      };
    }, [activeStep, previousStep]);

    return (
      <motion.div
        custom={activeStep - previousStep.current}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 30,
        }}>
        <Container>{children}</Container>
      </motion.div>
    );
  },
);
