import {ForwardedRef, forwardRef} from 'react';
import {Control, Controller} from 'react-hook-form';
import {InputProps, Input} from './Input';

interface ManagedInputProps extends InputProps {
  control: Control;
  controlKey: string;
  isRequiredMessage?: string;
  pattern?: {message: string; value: RegExp};
  customValidationFunc?: (currentValue: string) => true | string;
}

export const ManagedInput = forwardRef<HTMLInputElement, ManagedInputProps>(
  (
    {
      control,
      controlKey,
      isRequiredMessage,
      placeholder,
      customValidationFunc,
      pattern,
      ...props
    }: ManagedInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <Controller
        control={control}
        rules={{
          required: isRequiredMessage,
          pattern,
          validate: customValidationFunc,
        }}
        render={({fieldState: {error}, field: {onBlur, onChange, value}}) => {
          return (
            <Input
              {...props}
              ref={ref}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
              hasError={Boolean(error)}
              validationErrorMessage={error?.message}
            />
          );
        }}
        name={controlKey}
      />
    );
  },
);
