import {Palette} from 'hubbl-shared';
import {Navigate, Route, Routes} from 'react-router-dom';
import styled from 'styled-components';
import {AdminWebRoute} from './routes/AdminWebRoute';
import {ProtectedRoute} from './routes/ProtectedRoute';
import {RootRoute} from './routes/RootRoute';
import {
  SceneAddHubblSuggestion,
  SceneAddInterestStatistic,
  SceneAdminLandingPage,
  SceneDashboard,
  SceneEmployees,
  SceneGetStarted,
  SceneGetStartedCompleted,
  SceneInvited,
  SceneLogin,
  SceneProfile,
  SceneSignup,
} from './scenes';
import {LoadingAnimation} from './components/loading-animation/LoadingAnimation';

const AppContainer = styled.div`
  background-color: ${Palette.primarySubtleGreen};
`;

export const App = () => {
  return (
    <AppContainer className="app">
      <main className="content">
        <Routes>
          <Route path={RootRoute.LogIn} element={<SceneLogin />}></Route>
          <Route path={RootRoute.SignUp} element={<SceneSignup />}></Route>
          <Route path={RootRoute.GetStarted} element={<SceneGetStarted />} />
          <Route
            path={RootRoute.GetStartedCompleted}
            element={<SceneGetStartedCompleted />}
          />
          <Route path={RootRoute.Invited} element={<SceneInvited />} />
          <Route element={<ProtectedRoute />}>
            <Route
              path={RootRoute.AuthAdminLandingPage}
              element={<SceneAdminLandingPage />}>
              <Route index element={<SceneDashboard />} />

              <Route
                path={AdminWebRoute.Dashboard}
                element={<SceneDashboard />}></Route>
              <Route
                path={AdminWebRoute.Employees}
                element={<SceneEmployees />}></Route>
              <Route
                path={AdminWebRoute.AddInterestStatistic}
                element={<SceneAddInterestStatistic />}></Route>
              <Route
                path={AdminWebRoute.AddHubblSuggestion}
                element={<SceneAddHubblSuggestion />}></Route>
              <Route
                path={AdminWebRoute.Profile}
                element={<SceneProfile />}></Route>
              <Route
                path="*"
                element={<Navigate to={AdminWebRoute.Dashboard} replace />}
              />
            </Route>
          </Route>
          {/** TODO: Add not found scene */}
          <Route path="*" Component={() => <>'404 Page Not Found'</>} />
        </Routes>
      </main>
      <LoadingAnimation />
    </AppContainer>
  );
};
