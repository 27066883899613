import {AdminWebRoute} from './AdminWebRoute';

export enum RootRoute {
  LogIn = '/',
  SignUp = '/signup',
  GetStarted = '/get-started',
  GetStartedCompleted = '/get-started-complete',
  Invited = '/invited',
  AuthAdminLandingPage = '/auth-admin',
}

export type WebRoute = RootRoute | AdminWebRoute;
