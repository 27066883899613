import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import * as yup from 'yup';
import {Api} from '../../api';
import {useAppDispatch} from '../../hooks';
import {RootRoute} from '../../routes/RootRoute';
import {
  fetchCompany,
  fetchCompanyUsers,
} from '../../store/company/CompanyThunks';
import {fetchUserDetails} from '../../store/user/UserThunks';
import {getAuthUserIsAdmin} from '../../utils/user/getAuthUserIsAdmin';
import {useEffect} from 'react';

interface IFormInput {
  email: string;
  password: string;
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const StyledLabel = styled.label`
  font-size: 16px;
  color: #333;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const StyledInput = styled.input`
  height: 35px;
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;
const StyledSubmitButton = styled.input.attrs({
  type: 'submit',
})`
  margin-top: 20px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: #0056b3;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

// Sara was here

// yup schema
const schema = yup.object().shape({
  email: yup.string().email().required('Email is a required field'),
  password: yup
    .string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
});

export const SceneLogin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(RootRoute.GetStarted);
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const dispatch = useAppDispatch();

  const onClickLogin = (data: IFormInput) => {
    Api.user
      .onLogIn(data.email, data.password)
      .then(async authUser => {
        const isAdmin = await getAuthUserIsAdmin(authUser.user);
        if (isAdmin) {
          await dispatch(fetchUserDetails());
          await dispatch(fetchCompany());
          await dispatch(fetchCompanyUsers());

          navigate(RootRoute.AuthAdminLandingPage, {replace: true});
        } else {
          alert('You are not an admin');
        }
      })
      .catch(() => {});
  };

  return (
    <StyledContainer>
      <StyledForm onSubmit={handleSubmit(onClickLogin)}>
        <StyledLabel>Email</StyledLabel>
        <StyledInput {...register('email')} />
        {errors.email && <p>{errors.email.message}</p>}
        <StyledLabel>Password</StyledLabel>
        <StyledInput {...register('password')} />
        {errors.password && <p>{errors.password.message}</p>}

        <StyledSubmitButton type="submit" />
      </StyledForm>
    </StyledContainer>
  );
};
