import React, {memo} from 'react';
import {useWizard} from 'react-use-wizard';
import styled from 'styled-components';
import {AppLogo} from '../../../../../../assets/branding/AssetAppLogo';
import {PaddingBottom} from '../../../../../../components';
import {Palette} from 'hubbl-shared';

// StepIndicator Component
export const StepIndicator = memo(() => {
  const {stepCount, activeStep} = useWizard(); // Get the current step and total steps from the wizard

  return (
    <Container>
      <PaddingBottom amount={24} />
      <AppLogo size={120} />
      <PaddingBottom amount={24} />
      <IndicatorWrapper>
        <StepList>
          {Array.from({length: stepCount}, (_, index) => (
            <StepItem
              key={index}
              isCurrent={index === activeStep} // Highlight the current step
              isCompleted={index < activeStep} // Mark steps before current as completed
            ></StepItem>
          ))}
        </StepList>
      </IndicatorWrapper>
    </Container>
  );
});

// Styled Components
const Container = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
const IndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const StepItem = styled.li<{isCurrent: boolean; isCompleted: boolean}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({isCurrent, isCompleted}) => {
    if (isCurrent) return Palette.almostBlack; // Green for current step
    if (isCompleted) return Palette.white; // Gray for completed steps
    return Palette.white;
  }};
  color: ${({isCurrent, isCompleted}) => {
    if (isCurrent) return Palette.white; // White text for the current step
    if (isCompleted) return Palette.white; // White text for completed steps
    return Palette.white; // Dark text for upcoming steps
  }};
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;
