import {
  setGetStartedCompanyType,
  setWrittenCompanyName,
  setWrittenEmail,
  setSelectedPaymentPlan,
  setSelectedActivities,
  createCompany,
  setWrittenPhonenumber,
  setEntryPoint,
} from './GetStartedThunks';

export const GetStartedActions = {
  setGetStartedCompanyType,
  setWrittenCompanyName,
  setWrittenEmail,
  setEntryPoint,
  setSelectedActivities,
  setWrittenPhonenumber,
  createCompany,
  setSelectedPaymentPlan,
};
